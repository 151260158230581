import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { graphql } from 'gatsby';
import firebase from '../util/firebase';

// import assets
import chevron from '../images/icons/list-chevron.png';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../util/theme';

// import components
import Layout from '../components/Layout';
import { Grid } from '../components/Grid';

const ServicesBody = styled.div`
  padding: 20px 0 0 0;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 0 0 125px 0;
  }

  p {
    padding: 0 0 20px 0;

    a {
      color: ${getColor('primaryBlue')};
      font-weight: ${getFontWeight('medium')};
      text-decoration: underline;

      &:hover {
        color: ${getColor('accentGreen')};
      }
    }
  }
  .a-para {
    color: ${getColor('primaryBlue')};
    font-weight: ${getFontWeight('bold')};
    padding: 0 0 0 0;
  }

  h2 {
    color: ${getColor('primaryBlue')};
    margin: 25px 0;
    padding: 25px 0 15px 0;
  }

  h3 {
    background-color: ${getColor('accentOrange')};

    color: ${getColor('white')};

    margin: 10px 0;
    padding: 10px 20px;
  }

  .lists {
    display: flex;
    margin-left: 15px;

    justify-content: space-between;

    ul {
      flex-basis: 50%;

      margin: 15px 0 15px 5px;

      @media (min-width: ${getBreakpoint('md')}) {
        padding: 0 50px 0 0;
      }
      a {
        text-decoration: underline;
      }
      a:visited {
        color: inherit;
      }
    }
  }

  div {
    ul {
      list-style: url(${chevron}) outside;

      li {
        color: ${getColor('primaryBlue')};
        font-size: 1.6rem;
        font-weight: 600;

        margin: 0 0 15px 0;
        padding: 0 20px;
      }
    }
  }
`;

const Services = ({ data }) => {
  const [liveServices, setServices] = useState([]);

  const getServicesFromFireBase = () => {
    const db = firebase.firestore();
    db.collection('services')
      .get()
      .then(data => {
        const services = data.docs.map(doc => doc.data());
        setServices(services);
      });
  };

  useEffect(() => {
    getServicesFromFireBase();
  }, []);
  console.log(liveServices);

  const applicationServices = liveServices
    .filter(service => service.type === 'Applications')
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const CampaignsServices = liveServices
    .filter(service => service.type === 'Campaigns')
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  const supportServices = liveServices
    .filter(service => service.type === 'Services')
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const drawList = (items, start, end) => {
    const itemsToDraw = items.slice(start, end);
    return itemsToDraw.map(item => {
      const { id, name, url } = item;

      if (url) {
        return (
          <li key={id}>
            {url !== '*' ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            ) : (
              <p className="a-para">{name}</p>
            )}
          </li>
        );
      } else {
        return <li key={id}>{name}</li>;
      }
    });
  };

  return (
    <Layout>
      <Grid>
        <ServicesBody>
          <h2>Menu of Services</h2>
          <p>
            Click on the links below to find out more information about the
            Applications, Services, and Campaigns available to you! Interested
            in signing up for any of the marketing tools, services, and support
            available to you? Email{' '}
            <a href="mailto:marketing@onetrusthomeloans.com">
              marketing@onetrusthomeloans.com
            </a>{' '}
            to get started!
          </p>
          <h3>Applications</h3>
          <div className="lists">
            <ul>
              {drawList(applicationServices, 0, applicationServices.length / 2)}
            </ul>
            <ul>
              {drawList(
                applicationServices,
                applicationServices.length / 2,
                applicationServices.length
              )}
            </ul>
          </div>

          <h3>Services</h3>
          <div className="lists">
            <ul>{drawList(supportServices, 0, supportServices.length / 2)}</ul>
            <ul>
              {drawList(
                supportServices,
                supportServices.length / 2,
                supportServices.length
              )}
            </ul>
          </div>

          <h3>Campaigns</h3>
          <div className="lists">
            <ul>
              {drawList(CampaignsServices, 0, CampaignsServices.length / 2)}
            </ul>
            <ul>
              {drawList(
                CampaignsServices,
                CampaignsServices.length / 2,
                CampaignsServices.length
              )}
            </ul>
          </div>
        </ServicesBody>
      </Grid>
    </Layout>
  );
};

export default Services;

export const GET_MENU_DATA = graphql`
  query GET_MENU_DATA {
    allApplicationsJson {
      edges {
        node {
          id
          text
          url
          type
        }
      }
    }
    allServicesJson {
      edges {
        node {
          id
          text
          url
          type
        }
      }
    }
    allCampaignsJson {
      edges {
        node {
          id
          text
          url
          type
        }
      }
    }
  }
`;
